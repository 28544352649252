import React  from 'react';
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/exercise_list_new";
import { reduxForm,formValueSelector, change } from 'redux-form';
import PropTypes from 'prop-types';
import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {listExercise, deleteExercise, toggleExerciseFavorite,getExerciseFilters, listExerciseFilter} from '../../redux/exercise/network/exercise';
import ExerciseActionCreator from '../../redux/exercise/actions/exercise_list';
import Utils from '../../utils/utils';
import {history} from "../../redux/store";
import Swal from 'sweetalert2';
import HelpVideoCreator from '../../redux/core/actions/help_video_creator';
import { EXERCISE_LIST_VIDEO } from '../../utils/help_video';
import ClientAction from '../../redux/engagement/actions/client_creator';
import { createWorkoutFromWorkoutCart } from '../../redux/program/network/program_list';
import {PARTNER} from '../../core/constants/constants';

let golfOptions = [
    {
        "id": "gi_golf_warmup",
        "name": "Warm-up",
        "order": 1,
        "children": [
            {
                "id": "gi_golf_warmup_warmup1",
                "name": "Warm-up 1",
                "order": 1
            },
            {
                "id": "gi_golf_warmup_warmup2",
                "name": "Warm-up 2",
                "order": 2
            },
            {
                "id": "gi_golf_warmup_warmup3",
                "name": "Warm-up 3",
                "order": 3
            }
        ]
    },
    {
        "id": "gi_golf_fullswing",
        "name": "Full Swing",
        "order": 2,
        "children": [
            {
                "id": "gi_golf_fullswing_mobility1",
                "name": "Full Swing Mobility 1",
                "order": 1
            },
            {
                "id": "gi_golf_fullswing_mobility2",
                "name": "Full Swing Mobility 2",
                "order": 2
            },
            {
                "id": "gi_golf_fullswing_mobility3",
                "name": "Full Swing Mobility 3",
                "order": 3
            },
            {
                "id": "gi_golf_fullswing_balance1",
                "name": "Full Swing Balance 1",
                "order": 4
            },
            {
                "id": "gi_golf_fullswing_balance2",
                "name": "Full Swing Balance 2",
                "order": 5
            },
            {
                "id": "gi_golf_fullswing_balance3",
                "name": "Full Swing Balance 3",
                "order": 6
            },
            {
                "id": "gi_golf_fullswing_power1",
                "name": "Full Swing Power 1",
                "order": 7
            },
            {
                "id": "gi_golf_fullswing_power2",
                "name": "Full Swing Power 2",
                "order": 8
            },
            {
                "id": "gi_golf_fullswing_power3",
                "name": "Full Swing Power 3",
                "order": 9
            },
            {
                "id": "gi_golf_fullswing_power4",
                "name": "Full Swing Power 4",
                "order": 10
            },
            {
                "id": "gi_golf_fullswing_power5",
                "name": "Full Swing Power 5",
                "order": 11
            },
            {
                "id": "gi_golf_fullswing_power6",
                "name": "Full Swing Power 6",
                "order": 12
            }
        ]
    },
    {
        "id": "gi_golf_recovery",
        "name": "Recovery",
        "order": 3,
        "children": [
            {
                "id": "gi_golf_recovery_recovery1",
                "name": "Recovery 1",
                "order": 1
            },
            {
                "id": "gi_golf_recovery_recovery2",
                "name": "Recovery 2",
                "order": 2
            },
            {
                "id": "gi_golf_recovery_recovery3",
                "name": "Recovery 3",
                "order": 3
            }
        ]
    },
    {
        "id": "gi_golf_endurance",
        "name": "Endurance",
        "order": 4,
        "children": [
            {
                "id": "gi_golf_endurance_endurance1",
                "name": "Endurance 1",
                "order": 1
            },
            {
                "id": "gi_golf_endurance_endurance2",
                "name": "Endurance 2",
                "order": 2
            },
            {
                "id": "gi_golf_endurance_endurance3",
                "name": "Endurance 3",
                "order": 3
            },
            {
                "id": "gi_golf_endurance_endurance4",
                "name": "Endurance 4",
                "order": 4
            }
        ]
    },
    {
        "id": "gi_golf_position",
        "name": "Position",
        "order": 5,
        "children": [
            {
                "id": "gi_golf_position_address",
                "name": "Address",
                "order": 1,
                "children": [
                    {
                        "id": "gi_golf_position_address_mobility",
                        "name": "Address Mobility",
                        "order": 1
                    },
                    {
                        "id": "gi_golf_position_address_balance",
                        "name": "Address Balance",
                        "order": 2
                    },
                    {
                        "id": "gi_golf_position_address_strength",
                        "name": "Address Strength",
                        "order": 3
                    }
                ]
            },
            {
                "id": "gi_golf_position_backswing",
                "name": "Backswing",
                "order": 2,
                "children": [
                    {
                        "id": "gi_golf_position_backswing_mobility1",
                        "name": "Backswing Mobility 1",
                        "order": 1
                    },
                    {
                        "id": "gi_golf_position_backswing_mobility2",
                        "name": "Backswing Mobility 2",
                        "order": 2
                    },
                    {
                        "id": "gi_golf_position_backswing_mobility3",
                        "name": "Backswing Mobility 3",
                        "order": 3
                    },
                    {
                        "id": "gi_golf_position_backswing_mobility4",
                        "name": "Backswing Mobility 4",
                        "order": 4
                    },
                    {
                        "id": "gi_golf_position_backswing_balance1",
                        "name": "Backswing Balance 1",
                        "order": 5
                    },
                    {
                        "id": "gi_golf_position_backswing_balance2",
                        "name": "Backswing Balance 2",
                        "order": 6
                    },
                    {
                        "id": "gi_golf_position_backswing_balance3",
                        "name": "Backswing Balance 3",
                        "order": 7
                    },
                    {
                        "id": "gi_golf_position_backswing_balance4",
                        "name": "Backswing Balance 4",
                        "order": 8
                    },
                    {
                        "id": "gi_golf_position_backswing_strength1",
                        "name": "Backswing Strength 1",
                        "order": 9
                    },
                    {
                        "id": "gi_golf_position_backswing_strength2",
                        "name": "Backswing Strength 2",
                        "order": 10
                    },
                    {
                        "id": "gi_golf_position_backswing_strength3",
                        "name": "Backswing Strength 3",
                        "order": 11
                    },
                    {
                        "id": "gi_golf_position_backswing_strength4",
                        "name": "Backswing Strength 4",
                        "order": 12
                    },
                    {
                        "id": "gi_golf_position_backswing_endurance1",
                        "name": "Backswing Endurance 1",
                        "order": 13
                    },
                    {
                        "id": "gi_golf_position_backswing_endurance2",
                        "name": "Backswing Endurance 2",
                        "order": 14
                    },
                    {
                        "id": "gi_golf_position_backswing_endurance3",
                        "name": "Backswing Endurance 3",
                        "order": 15
                    },
                    {
                        "id": "gi_golf_position_backswing_endurance4",
                        "name": "Backswing Endurance 4",
                        "order": 16
                    }
                ]
            },
            {
                "id": "gi_golf_position_impact",
                "name": "Impact",
                "order": 3,
                "children": [
                    {
                        "id": "gi_golf_position_impact_mobility1",
                        "name": "Impact Mobility 1",
                        "order": 1
                    },
                    {
                        "id": "gi_golf_position_impact_mobility2",
                        "name": "Impact Mobility 2",
                        "order": 2
                    },
                    {
                        "id": "gi_golf_position_impact_balance1",
                        "name": "Impact Balance 1",
                        "order": 3
                    },
                    {
                        "id": "gi_golf_position_impact_balance2",
                        "name": "Impact Balance 2",
                        "order": 4
                    },
                    {
                        "id": "gi_golf_position_impact_strength",
                        "name": "Impact Strength",
                        "order": 5
                    },
                    {
                        "id": "gi_golf_position_impact_power1",
                        "name": "Impact Power 1",
                        "order": 6
                    },
                    {
                        "id": "gi_golf_position_impact_power2",
                        "name": "Impact Power 2",
                        "order": 7
                    },
                    {
                        "id": "gi_golf_position_impact_power3",
                        "name": "Impact Power 3",
                        "order": 8
                    }
                ]
            },
            {
                "id": "gi_golf_position_finish",
                "name": "Finish",
                "order": 4,
                "children": [
                    {
                        "id": "gi_golf_position_finish_mobility1",
                        "name": "Finish Mobility 1",
                        "order": 1
                    },
                    {
                        "id": "gi_golf_position_finish_mobility2",
                        "name": "Finish Mobility 2",
                        "order": 2
                    },
                    {
                        "id": "gi_golf_position_finish_mobility3",
                        "name": "Finish Mobility 3",
                        "order": 3
                    },
                    {
                        "id": "gi_golf_position_finish_mobility4",
                        "name": "Finish Mobility 4",
                        "order": 4
                    },
                    {
                        "id": "gi_golf_position_finish_balance1",
                        "name": "Finish Balance 1",
                        "order": 5
                    },
                    {
                        "id": "gi_golf_position_finish_balance2",
                        "name": "Finish Balance 2",
                        "order": 6
                    },
                    {
                        "id": "gi_golf_position_finish_balance3",
                        "name": "Finish Balance 3",
                        "order": 7
                    },
                    {
                        "id": "gi_golf_position_finish_balance4",
                        "name": "Finish Balance 4",
                        "order": 8
                    },
                    {
                        "id": "gi_golf_position_finish_strength1",
                        "name": "Finish Strength 1",
                        "order": 9
                    },
                    {
                        "id": "gi_golf_position_finish_strength2",
                        "name": "Finish Strength 2",
                        "order": 10
                    },
                    {
                        "id": "gi_golf_position_finish_strength3",
                        "name": "Finish Strength 3",
                        "order": 11
                    },
                    {
                        "id": "gi_golf_position_finish_strength4",
                        "name": "Finish Strength 4",
                        "order": 12
                    },
                    {
                        "id": "gi_golf_position_finish_endurance1",
                        "name": "Finish Endurance 1",
                        "order": 13
                    },
                    {
                        "id": "gi_golf_position_finish_endurance2",
                        "name": "Finish Endurance 2",
                        "order": 14
                    },
                    {
                        "id": "gi_golf_position_finish_endurance3",
                        "name": "Finish Endurance 3",
                        "order": 15
                    },
                    {
                        "id": "gi_golf_position_finish_endurance4",
                        "name": "Finish Endurance 4",
                        "order": 16
                    }
                ]
            }
        ]
    },
    {
        "id": "gi_golf_goal",
        "name": "Goal",
        "order": 6,
        "children": [
            {
                "id": "gi_golf_goal_mobility",
                "name": "Mobility",
                "order": 1,
                "children": [
                    {
                        "id": "gi_golf_goal_mobility_address",
                        "name": "Mobility Address",
                        "order": 1
                    },
                    {
                        "id": "gi_golf_goal_mobility_backswing1",
                        "name": "Mobility Backswing 1",
                        "order": 2
                    },
                    {
                        "id": "gi_golf_goal_mobility_backswing2",
                        "name": "Mobility Backswing 2",
                        "order": 3
                    },
                    {
                        "id": "gi_golf_goal_mobility_backswing3",
                        "name": "Mobility Backswing 3",
                        "order": 4
                    },
                    {
                        "id": "gi_golf_goal_mobility_backswing4",
                        "name": "Mobility Backswing 4",
                        "order": 5
                    },
                    {
                        "id": "gi_golf_goal_mobility_impact1",
                        "name": "Mobility Impact 1",
                        "order": 6
                    },
                    {
                        "id": "gi_golf_goal_mobility_impact2",
                        "name": "Mobility Impact 2",
                        "order": 7
                    },
                    {
                        "id": "gi_golf_goal_mobility_finish1",
                        "name": "Mobility Finish 1",
                        "order": 8
                    },
                    {
                        "id": "gi_golf_goal_mobility_finish2",
                        "name": "Mobility Finish 2",
                        "order": 9
                    },
                    {
                        "id": "gi_golf_goal_mobility_finish3",
                        "name": "Mobility Finish 3",
                        "order": 10
                    },
                    {
                        "id": "gi_golf_goal_mobility_finish4",
                        "name": "Mobility Finish 4",
                        "order": 11
                    }
                ]
            },
            {
                "id": "gi_golf_goal_balance",
                "name": "Balance",
                "order": 2,
                "children": [
                    {
                        "id": "gi_golf_goal_balance_address",
                        "name": "Balance Address",
                        "order": 1
                    },
                    {
                        "id": "gi_golf_goal_balance_backswing1",
                        "name": "Balance Backswing 1",
                        "order": 2
                    },
                    {
                        "id": "gi_golf_goal_balance_backswing2",
                        "name": "Balance Backswing 2",
                        "order": 3
                    },
                    {
                        "id": "gi_golf_goal_balance_backswing3",
                        "name": "Balance Backswing 3",
                        "order": 4
                    },
                    {
                        "id": "gi_golf_goal_balance_backswing4",
                        "name": "Balance Backswing 4",
                        "order": 5
                    },
                    {
                        "id": "gi_golf_goal_balance_impact1",
                        "name": "Balance Impact 1",
                        "order": 6
                    },
                    {
                        "id": "gi_golf_goal_balance_impact2",
                        "name": "Balance Impact 2",
                        "order": 7
                    },
                    {
                        "id": "gi_golf_goal_balance_finish1",
                        "name": "Balance Finish 1",
                        "order": 8
                    },
                    {
                        "id": "gi_golf_goal_balance_finish2",
                        "name": "Balance Finish 2",
                        "order": 9
                    },
                    {
                        "id": "gi_golf_goal_balance_finish3",
                        "name": "Balance Finish 3",
                        "order": 10
                    },
                    {
                        "id": "gi_golf_goal_balance_finish4",
                        "name": "Balance Finish 4",
                        "order": 11
                    }
                ]
            },
            {
                "id": "gi_golf_goal_strength",
                "name": "Strength",
                "order": 3,
                "children": [
                    {
                        "id": "gi_golf_goal_strength_address",
                        "name": "Strength Address",
                        "order": 1
                    },
                    {
                        "id": "gi_golf_goal_strength_backswing1",
                        "name": "Strength Backswing 1",
                        "order": 2
                    },
                    {
                        "id": "gi_golf_goal_strength_backswing2",
                        "name": "Strength Backswing 2",
                        "order": 3
                    },
                    {
                        "id": "gi_golf_goal_strength_backswing3",
                        "name": "Strength Backswing 3",
                        "order": 4
                    },
                    {
                        "id": "gi_golf_goal_strength_backswing4",
                        "name": "Strength Backswing 4",
                        "order": 5
                    },
                    {
                        "id": "gi_golf_goal_strength_impact",
                        "name": "Strength Impact",
                        "order": 6
                    },
                    {
                        "id": "gi_golf_goal_strength_finish1",
                        "name": "Strength Finish 1",
                        "order": 7
                    },
                    {
                        "id": "gi_golf_goal_strength_finish2",
                        "name": "Strength Finish 2",
                        "order": 8
                    },
                    {
                        "id": "gi_golf_goal_strength_finish3",
                        "name": "Strength Finish 3",
                        "order": 9
                    },
                    {
                        "id": "gi_golf_goal_strength_finish4",
                        "name": "Strength Finish 4",
                        "order": 10
                    }
                ]
            },
            {
                "id": "gi_golf_goal_power",
                "name": "Power",
                "order": 4,
                "children": [
                    {
                        "id": "gi_golf_goal_power_impact1",
                        "name": "Power Impact 1",
                        "order": 1
                    },
                    {
                        "id": "gi_golf_goal_power_impact2",
                        "name": "Power Impact 2",
                        "order": 2
                    },
                    {
                        "id": "gi_golf_goal_power_impact3",
                        "name": "Power Impact 3",
                        "order": 3
                    },
                    {
                        "id": "gi_golf_goal_power_fullswing1",
                        "name": "Power Full Swing 1",
                        "order": 4
                    },
                    {
                        "id": "gi_golf_goal_power_fullswing2",
                        "name": "Power Full Swing 2",
                        "order": 5
                    },
                    {
                        "id": "gi_golf_goal_power_fullswing3",
                        "name": "Power Full Swing 3",
                        "order": 6
                    },
                    {
                        "id": "gi_golf_goal_power_fullswing4",
                        "name": "Power Full Swing 4",
                        "order": 7
                    },
                    {
                        "id": "gi_golf_goal_power_fullswing5",
                        "name": "Power Full Swing 5",
                        "order": 8
                    },
                    {
                        "id": "gi_golf_goal_power_fullswing6",
                        "name": "Power Full Swing 6",
                        "order": 9
                    }
                ]
            }
        ]
    }
];
let performanceData = [
    { "id": "ANALYSIS_MOBILITY", name: "Mobility" },
    { "id": "ANALYSIS_STABILITY", name: "Stability" },
    { "id": "PERFORMANCE_SUPPORT", name: "Performance - Support" },
    { "id": "PERFORMANCE_ELEVATED_LUNGELEG", name: "Performance - Elevated (Lunge Leg)" },
    { "id": "PERFORMANCE_FIXEDTRUNK", name: "Performance - Fixed Trunk" },
    { "id": "PERFORMANCE_UNILATERALHANDSWING", name: "Performance - Unilateral Hand Swing" },
    { "id": "PERFORMANCE_PLANE_HANDS", name: "Performance - Plane (Hands)" },
    { "id": "PERFORMANCE_PLANE_FOOT", name: "Performance - Plane (Foot)" },
    { "id": "PERFORMANCE_HYBRID_HANDS", name: "Performance - Hybrid (Hands)" },
    { "id": "PERFORMANCE_HYBRID_FOOT", name: "Performance - Hybrid (Foot)" },
    { "id": "PERFORMANCE_PIVOT_IN_CHAIN", name: "Performance - Pivot (In-Chain)" },
    { "id": "PERFORMANCE_PIVOT_OUT_OF_CHAIN", name: "Performance - Pivot (Out-of-Chain)" },
    { "id": "PERFORMANCE_LOAD", name: "Performance - Load" },
    { "id": "PERFORMANCE_ELEVATED_STANCELEG", name: "Performance - Elevated (Stance Leg)" },
    { "id": "PERFORMANCE_LOCOMOTOR", name: "Performance - Locomotor" },
    { "id": "PERFORMANCE_SPHERICAL", name: "Performance - Spherical" }
];

let mostData = [
    { "id": "MOBILITY", name: "Mobility" },
    { "id": "STABILITY", name: "Stability" }
];

let matricData = [
    { "id": "ANT", name: "ANT" },
    { "id": "PST", name: "PST" },
    { "id": "SSL", name: "SSL" },
    { "id": "OSL", name: "OSL" },
    { "id": "SSR", name: "SSR" },
    { "id": "OSR", name: "OSR" }
];


class ExerciseList extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
        this.toggleFavorite = this.toggleFavorite.bind(this);
        this.preview = this.preview.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.drawerToggle = this.drawerToggle.bind(this);
        this.selectRender = this.selectRender.bind(this);
        this.valueRender = this.valueRender.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.onSearch = _.debounce(this.onSearch.bind(this),1000);
        this.setFilterPreferences = _.debounce(this.setFilterPreferences.bind(this),1000);
        this.giGolfFilter1Select = this.giGolfFilter1Select.bind(this);
        this.giGolfFilter2Select = this.giGolfFilter2Select.bind(this);
        this.addExerciseToWorkoutCart = this.addExerciseToWorkoutCart.bind(this);
        this.createWorkoutFromWorkoutCart = this.createWorkoutFromWorkoutCart.bind(this);
        this.toggleFilterOptions = this.toggleFilterOptions.bind(this);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.goToDashboard = this.goToDashboard.bind(this);

        this.state = {
            search:"",
            filterPreferences:{first:true},
            previewingExercise: null,            
            showFilter:false,
            gi_golf_library_level1_value: null,
            gi_golf_library_level2_value: null,
            gi_golf_library_level2_options: null,
            gi_golf_library_level3_value: null,
            gi_golf_library_level3_options: null,
            exercise_library_to_be_loaded: null,
            showFilterOptions: false,
            all_exercises_checked: true,
        };
    }
    setActive(el, active){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    goToDashboard(){
        history.push(`/practice/${this.props.selectedRole.practice.id}/home`)
    }
    toggleFilterOptions(){
        let newState = !this.state.showFilterOptions
        this.setState({
            showFilterOptions: newState,
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.loadMore(false,true);
        // this.props.listExerciseFilter({ first:true });

        if(this.props.match !== undefined && this.props.match.params.library === "3dmaps") {
            this.setState({
                exercise_library_to_be_loaded: this.props.match.params.library,
                filterPreferences: {first:true, 'threedmaps_filter': 'gi_3dmaps'}
            }, () => {
                this.fetchFilterData();    
            });
        } else if(this.props.match !== undefined && this.props.match.params.library === "gi_golf") {
            this.setState({
                exercise_library_to_be_loaded: this.props.match.params.library,
                filterPreferences: {first:true, 'threedmaps_filter': 'gi_golf'}
            }, () => {
                this.fetchFilterData();    
            });
        } else if(this.props.match !== undefined && this.props.match.params.library === "gi") {
            this.setState({
                exercise_library_to_be_loaded: this.props.match.params.library,
                filterPreferences: {first:false, 'partners': ['2'], 'my_exercises': false, 'my_practice_exercise': false}
            }, () => {
                this.fetchFilterData();    
            });
        } else {
            this.fetchFilterData();
        }       
        this.props.updateHelpVideo(EXERCISE_LIST_VIDEO);
    }

    componentWillReceiveProps(nextProps) {
        if(!_.isEmpty(this.props.current_filters) &&  !_.isEqual(this.props.current_filters,nextProps.current_filters)){
            this.setFilterPreferences(nextProps.current_filters);
        }
    }

    giGolfFilter1Select(value) {
        if(value !== null) {
            this.setState({
                gi_golf_library_level2_options: value.children,
            });            
        }        
    }

    giGolfFilter2Select(value) {
        if(value !== null) {
            this.setState({
                gi_golf_library_level3_options: value.children
            })
        };
    }

    selectBadgeLessRender(option){
        return (
            <span>{option.label ? option.label : option.name}</span>
        );
    }

    selectRender(option){
        return (
            <span>{option.label ? option.label : option.name}<span className="pull-right badge bg-success" >{option.count ? option.count : 0}</span></span>
        );
    }

    valueRender(option){
        return (
            <span className="vertical-align">{option.label ? option.label : option.name}<span className="badge bg-success m-l-10" >{option.count ? option.count : 0}</span></span>
        );
    }

    applyFilter(){
        this.setState({showFilter:false});
        this.loadMore();
    }
    resetFilter(){
        this.props.reset();
    }
    
    loadMore(increment=false,first=false) {
        let params = { ...this.props.paginateInfo,...this.state.filterPreferences};
        if(first){
            params.first = first; 
            if(this.props.match !== undefined && this.props.match.params.library === '3dmaps'){
                params.threedmaps_filter = 'gi_3dmaps';
            } else if(this.props.match !== undefined && this.props.match.params.library === 'gi_golf') {
                params.threedmaps_filter = 'gi_golf';
            } else if(this.props.match !== undefined && this.props.match.params.library === 'gi') {
                params.first = false;
                params.partners = ['2','10']
                params.my_exercises = false;
                params.my_practice_exercise = false;
            }
        }
        if(this.state.all_exercises_checked) {
            params.partners = ['2','10'],
            params.my_exercises = true,  // extra to view full list
            params.my_practice_exercise = true //extra to view full list
        }
        params.search = this.state.search;
        params.first = false;
        params.exercise_filter_subcategory_options = this.props.exercise_filter_subcategory_options;
        if(increment){
            params.page = params.page + 1;
        }else{
            params.page = 0;
        }
        this.props.listExercise(params)
        .then(()=>{ this.props.listExerciseFilter({ first:true });})
    }

    setFilterPreferences(filterData) {
        let params = {};
        if(this.props.match !== undefined && this.props.match.params.library === '3dmaps'){
            params.threedmaps_filter = 'gi_3dmaps';
        } else if(this.props.match !== undefined && this.props.match.params.library === 'gi_golf') {
            params.threedmaps_filter = 'gi_golf';
        } else if(this.props.match !== undefined && this.props.match.params.library === 'gi') {
            params.partners = ['2']
            params.my_exercises = false;
            params.my_practice_exercise = false;
        }
        if(_.has(filterData,"is_favorite")){
            params.is_favorite = filterData.is_favorite;
        }        
        if(_.has(filterData,"is_evidence_based")){
            params.is_evidence_based = filterData.is_evidence_based;
        }                
        if(filterData.my_deleted_exercises) {
            params["my_deleted_exercises"] = true;
            this.setState({filterPreferences:params});
        } else if (filterData.threed_map) {
            let threedmaps_filter = "gi_3dmaps";
            if (filterData.performance) {
                if(typeof filterData.performance === 'object'){
                    threedmaps_filter = threedmaps_filter + "_" + filterData.performance.id;
                } else {
                    threedmaps_filter = threedmaps_filter + "_" + filterData.performance;
                }
            }
            if (filterData.most) {
                if(typeof filterData.most === 'object'){
                    threedmaps_filter = threedmaps_filter + "_" + filterData.most.id;
                } else {
                    threedmaps_filter = threedmaps_filter + "_" + filterData.most;
                }
            }            
            if (filterData.matrix) {
                if(typeof filterData.matrix === 'object'){
                    threedmaps_filter = threedmaps_filter + "_" + filterData.matrix.id;
                } else {
                    threedmaps_filter = threedmaps_filter + "_" + filterData.matrix;
                }
            }
            params.threedmaps_filter = threedmaps_filter;            
            this.setState({filterPreferences:params});
        } else if(filterData.gi_golf_library) {
            let gi_golf_filter = "gi_golf";
            if(filterData.gi_golf_library_level3 !== undefined && filterData.gi_golf_library_level3 !== null) {
                gi_golf_filter = filterData.gi_golf_library_level3;
            } else if(filterData.gi_golf_library_level2 !== undefined && filterData.gi_golf_library_level2 !== null) {
                gi_golf_filter = filterData.gi_golf_library_level2;
            } else if(filterData.gi_golf_library_level1 !== undefined && filterData.gi_golf_library_level1 !== null) {
                gi_golf_filter = filterData.gi_golf_library_level1;
            }
            params.threedmaps_filter = gi_golf_filter;            
            this.setState({filterPreferences: params});
        } else {
            params["my_deleted_exercises"] = false;
            if(filterData.action &&  filterData.action.length > 0){
                params.action =  Utils.makeObjectArrayFlat(filterData.action,"id");
            }
            if(filterData.body_part &&  filterData.body_part.length > 0){
                params.body_part =  Utils.makeObjectArrayFlat(filterData.body_part,"id");
            }
            if(filterData.cues &&  filterData.cues.length > 0){
                params.cues =  Utils.makeObjectArrayFlat(filterData.cues,"id");
            }
            if(filterData.difficulty &&  filterData.difficulty.length > 0){
                params.difficulty =  Utils.makeObjectArrayFlat(filterData.difficulty,"value");
            }
            if(filterData.direction &&  filterData.direction.length > 0){
                params.direction =  Utils.makeObjectArrayFlat(filterData.direction,"id");
            }
            if(filterData.driver &&  filterData.driver.length > 0){
                params.driver =  Utils.makeObjectArrayFlat(filterData.driver,"id");
            }
            if(filterData.equipments &&  filterData.equipments.length > 0){
                params.equipments =  Utils.makeObjectArrayFlat(filterData.equipments,"id");
            }
            if(filterData.exercise_types &&  filterData.exercise_types.length > 0){
                params.exercise_types =  Utils.makeObjectArrayFlat(filterData.exercise_types,"id");
            }
            if(filterData.metric &&  filterData.metric.length > 0){
                params.metric = filterData.metric;
                params.metric =  Utils.makeObjectArrayFlat(filterData.metric,"value");
            }
            if(filterData.category && filterData.category){
                params.category = filterData.category;
                if(filterData.category === 5 &&  filterData.sports && filterData.sports.length > 0){
                    params.sports =  Utils.makeObjectArrayFlat(filterData.sports,"id"); 
                }
                if(filterData.category === 5  && filterData.sports_exercise_type && filterData.sports_exercise_type.id){
                    params.sports_exercise_type =  filterData.sports_exercise_type.id;
                }
            }
            if(_.includes(filterData.exercise_sources, "my_exercises")){
                params.my_exercises = true;
            }            
            if(_.includes(filterData.exercise_sources, "my_practice_exercise")){
                params.my_practice_exercise = true;
            }
            let partners  = _.filter(filterData.exercise_sources,(item)=>{
                if(item === "my_exercises" || item === "my_practice_exercise"){
                    return true;
                }else{
                    return true;
                }
            });

            if(partners.length > 0){
                // params.partners = partners;
                if(this.props.exercise_sources.includes("all_exercises")){
                    params.partners= ["my_exercises", "my_practice_exercise",'2','10'];
                    this.setState({
                        all_exercises_checked: true
                    })
                }else {
                    params.partners= ["my_exercises", "my_practice_exercise"];
                    this.setState({
                        all_exercises_checked: false
                    })
                }
            }
            this.setState({filterPreferences:params},()=>{
                this.fetchFilterData();
            });
        }
    }

    fetchFilterData(){
        this.props.getExerciseFilters(this.state.filterPreferences);
    }

    drawerToggle = () => {
        this.setState({showFilter: !this.state.showFilter});
      };
 
    preview(exercise) {
        this.setState({
            previewingExercise: exercise,
        });
    }

    toggleFavorite(id) {
        this.props.toggleExerciseFavorite({
             id: id
        }).then((response) => {
            this.fetchFilterData();
        }).catch(() => { });
    }

    delete(id, toggle_visibility){
        let that = this;
        let display_text = "";
        if(toggle_visibility === "hide") {
            display_text = "Are you sure you want to hide this exercise";
        } else {
            display_text = "Are you sure you want to unhide this exercise";
        }
        Swal.fire({
            title: "Are you sure?",
            text: display_text,
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (result) {    
            if(result.value) {
                that.props.deleteExercise({
                    "exercise_id": id,
                    "toggle_visibility": toggle_visibility,
                }).then(()=>{
                }).catch(()=>{});            
            }
        }, function (dismiss) {
                        
        });
    }

    onSearch(e){
        this.setState({search:e.target.value},()=>{
                this.loadMore();
        });
    }

    add(){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/engagement/exercise/add");
    }

    edit(id){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/engagement/exercise/edit/"+id);
    }

    addExerciseToWorkoutCart(exercise) {
        this.props.addExerciseToWorkoutCartActionCreator(exercise);
        Utils.showInfoAlert("Exercise has been added to workout cart");
    }

    createWorkoutFromWorkoutCart() {
        if(this.props.workoutCartExeciseList.length === 0) {
            Utils.showErrorAlert("You have not added any exercise to the workout cart");
        } else {
            let that = this;
            Swal.fire({
                title: "Are you sure?",
                text: "Would you like to create a workout for your client " + this.props.selectedClientForAction["name"] + " from exercises selected in the workout cart?",
                type: "info",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "#0292d3",
                cancelButtonColor: "#4C555C",
                confirmButtonText: "Yes, I am sure!",
                cancelButtonText: "No",
                useRejections:true
            }).then(function () {
                let params = {};
                params["client_id"] = that.props.selectedClientForAction["id"];
                params["engagement_id"] = that.props.selectedClientForAction["active_engagement_id"];
                params["name"] = "Workout";
                params["description"] = "Workout Description";
                params["schedule_type"] = 2;
                params["total_days"] = 7;
                params["start_date"] = "2019-03-10";
                params["per_day"] = 1;
                params["per_week"] = 7;
                params["progression_name"] = "Phase 1";
                params["progression_description"] = "Phase 1 Description";
                params["progression_duration"] = 60;                              
                params["progression_exercises"] = that.props.workoutCartExeciseList;  
                that.props.createWorkoutFromWorkoutCart(params).then((response2) => {
                    if(that.props.business_partner.app_type === "gi") {
                        history.push("/practice/"+that.props.selectedRole.practice.id+"/client/engagement/" + params["engagement_id"] + "?tab=1&workout="+response2["id"]);
                    } else {
                        history.push("/practice/"+that.props.selectedRole.practice.id+"/client/engagement/" + params["engagement_id"] + "?tab=3&workout="+response2["id"]);
                    }
                }); 
            }, function (dismiss) {
                            
            });                 
        }
    }

    render() {
        if(typeof this.props.gi_golf_library_level1 === 'object'){
            this.props.change('gi_golf_library_level1', this.props.gi_golf_library_level1.value)
        }else if(typeof this.props.gi_golf_library_level2 === 'object'){
            this.props.change('gi_golf_library_level2', this.props.gi_golf_library_level2.value)
        }else if(typeof this.props.gi_golf_library_level3 === 'object'){
            this.props.change('gi_golf_library_level3', this.props.gi_golf_library_level3.value)
        } 
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.clean();
        this.props.clearHelpVideo();
        this.props.clearClientSelection();
        this.props.clearWorkoutCartActionCreator();
    }

}

ExerciseList.propTypes = {
    exerciseSelectionMode: PropTypes.bool,
    onExerciseSelection: PropTypes.func,
};
ExerciseList.defaultProps = {
    exerciseSelectionMode: false,
    onExerciseSelection: () => { },
};
const selector = formValueSelector('ExerciseFilterForm');
function mapDispatchToProps(dispatch) {
    return (
        bindActionCreators({
            deleteExercise,
            listExerciseFilter,
            listExercise,
            toggleExerciseFavorite,
            getExerciseFilters,
            clean: ExerciseActionCreator.cleanModule,
            incrementPageCount: ExerciseActionCreator.incrementPageCount,
            updateHelpVideo: HelpVideoCreator.updateHelpVideo,
            clearHelpVideo: HelpVideoCreator.clearHelpVideo,
            clearClientSelection: ClientAction.clientSelectionClear,
            addExerciseToWorkoutCartActionCreator: ExerciseActionCreator.addExerciseToWorkoutCart,
            clearWorkoutCartActionCreator: ExerciseActionCreator.clearWorkoutCart,
            createWorkoutFromWorkoutCart,
            change,
        }, dispatch)
    );
}

function mapStateToProps(state, ownProps) {    
    let exerciseSources = [];
    let filterData = state.exercise.exerciseList.filterData;
    // let filterData = {};
    // filterData.partners = [{
    //     count: 5757,
    //     id: 2,
    //     name: "Gray Institute"
    // },
    // {
    //     count: 5757,
    //     id: 10,
    //     name: "DHF"
    // }];
    let searchPreferences = state.exercise.exerciseList.searchPreferences;
    let initialValues = {
        exercise_sources:[],
        category:null,
        direction:[],
        cues:[],
        sports_exercise_type:[],
        difficulty:[],
        body_part:[],
        metric:[],
        driver:[],
        equipments:[],
        action:[],
        is_favorite: _.has(searchPreferences, "is_favorite") ? searchPreferences.is_favorite : false,
        is_evidence_based:_.has(searchPreferences,"is_evidence_based")  ? searchPreferences.is_evidence_based : false,
        threed_map: ownProps.match !== undefined && ownProps.match.params.library === '3dmaps' ? true : false,
        gi_golf_library: ownProps.match !== undefined && ownProps.match.params.library === 'gi_golf' ? true : false,
    };
    if(_.has(filterData,"partners")){
        initialValues.exercise_sources.push('all_exercises');
    }
    if(_.has(searchPreferences,"my_exercises") && searchPreferences.my_exercises){
        initialValues.exercise_sources.push("my_exercises");
    }    
    if(_.has(searchPreferences,"my_practice_exercise") && searchPreferences.my_practice_exercise){
        initialValues.exercise_sources.push("my_practice_exercise");
    }
    if(!_.isEmpty(filterData)){
        exerciseSources.push({label:"My Exercises",value:"my_exercises",count:filterData.my_exercises});
        exerciseSources.push({label:"My Practice Exercises",value:"my_practice_exercise",count:filterData.practice});

        if(!_.isEmpty(filterData.partners)){
            let count= 0;
            // let id = [];
            _.forEach(filterData.partners,(item)=>{
                count+=item.count;
            });

            exerciseSources.push({label:"All Exercises", value: 'all_exercises', count: count});
        }
    }
    let exercise_filter_subcategory_options= selector(state, 'exercise_filter_subcategory_options')
    if(exercise_filter_subcategory_options){
        exercise_filter_subcategory_options = Object.keys(exercise_filter_subcategory_options).filter(function (key) {
            if(exercise_filter_subcategory_options[key] ===  true){
                return key; 
            }
        });
    }

    let threed_map = selector(state, 'threed_map');
    let gi_golf_library = selector(state, 'gi_golf_library');    
    if(threed_map) {
        gi_golf_library = false;
    }else if(gi_golf_library) {
        threed_map = false; 
    }
    return {
        golfOptions,
        performanceData,
        mostData,
        matricData,
        exerciseFilters: state.exercise.exerciseList.exerciseFilters,
        exerciseList: state.exercise.exerciseList.exerciseList,
        selectedRole: state.auth.roleReducer.selectedRole,
        is3DMAPSEnabled: state.auth.roleReducer.selectedRole.is_3dmaps_enabled,
        isGIGolfEnabled: state.auth.roleReducer.selectedRole.is_gi_golf_enabled,
        paginateInfo: state.exercise.exerciseList.paginateInfo,
        selectedClientForAction: state.engagement.clientReducer.selected_client_for_action,
        filterData:filterData,
        exerciseSources:exerciseSources,
        initialValues:initialValues,
        my_deleted_exercises: selector(state, 'my_deleted_exercises'),
        threed_map: threed_map,
        gi_golf_library: gi_golf_library,      
        performance: selector(state, 'performance'),
        category: selector(state, 'category'),
        exercise_sources: selector(state, 'exercise_sources'),
        exercise_filter_subcategory_options: exercise_filter_subcategory_options,
        workoutCartExeciseList: state.exercise.exerciseList.workoutCartExeciseList,
        business_partner: state.practitioner.practitionerReducer.business_partner,
        current_filters: selector(
            state,
            'my_deleted_exercises',
            'threed_map',
            'performance',
            'most',
            'matrix',
            'gi_golf_library',
            'gi_golf_library_level1',
            'gi_golf_library_level2',
            'gi_golf_library_level3',
            'exercise_sources',
            'category', 
            'sports', 
            'sports_exercise_type',
            'metric',
            'body_part',
            'difficulty',
            'cues',
            'equipments',
            'exercise_types',
            'action',
            'driver',
            'direction',
            'is_favorite',
            'is_evidence_based',
            // 'exercise_filter_category',
            // 'exercise_filter_subcategory',
            'exercise_filter_subcategory_options',
    ),
    gi_golf_library_level1: selector(state, 'gi_golf_library_level1'),
    gi_golf_library_level2: selector(state, 'gi_golf_library_level2'),
    gi_golf_library_level3: selector(state, 'gi_golf_library_level3'),
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const ExerciseListFilterForm = reduxForm(
    {
        form: 'ExerciseFilterForm',
        enableReinitialize: true,
        validate
    }
)(ExerciseList);

const ExerciseListConnected = connect(mapStateToProps,mapDispatchToProps)(ExerciseListFilterForm);
export default ExerciseListConnected;
