import * as React from 'react';
import * as _ from 'lodash';
import { Layout } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Sidebar } from 'react-toolbox';
import Button from 'react-toolbox/lib/button';
import { SearchInput as Input } from '../../../utils/common_components/textInput_form';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import TooltipWrapper from '../../../utils/common_components/tooltipWrapper';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import { Field } from 'redux-form';
import Select from '../../../utils/common_components/select';
import { tetheredSelectAsynFunction as AsyncSelect } from '../../../utils/common_components/select_form';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Checkboxes from '../../../utils/common_components/checkbox_group_form';
import Checkbox from '../../../utils/common_components/checkbox_form';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form';
import { editorConfig } from '../../../utils/common_components/rich_text_editor_form';
import { Dialog } from 'react-toolbox/lib/dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import ExercisePreview from '../exercise_preview';
export default function () {
    function repeatExercise1(exercise, index) {
        return React.createElement('div', {
            'className': 'width-' + (this.props.exerciseSelectionMode ? '135' : '260 m-l-10') + '  box-shadow bg-white  m-r-6 m-t-18 ribbon-container',
            'key': 'exercise_list' + index
        }, exercise.state === 1 ? React.createElement('div', {
            'className': 'bg-warning ribbon',
            'key': '4315'
        }, '\n                Draft\n            ') : null, exercise.state === 2 ? React.createElement('div', {
            'className': 'bg-published ribbon',
            'key': '4427'
        }, '\n                Published\n            ') : null, React.createElement('div', {
            'className': 'thumbnail box-' + (this.props.exerciseSelectionMode ? '135-75' : '260-150'),
            'style': { backgroundImage: 'url(' + encodeURI(exercise.exercise_thumbnail_url) + ')' }
        }), React.createElement('div', {
            'className': 'row bg-white center-xs middle-xs m-0 m-t-8 p-l-5',
            'style': { height: '30px' }
        }, React.createElement(TooltipWrapper, {
            'className': 'text-left col-xs-12 f-16 truncate',
            'tooltip': exercise.name
        }, '\n                    ', exercise.name, '\n                ')), React.createElement('div', { 'className': 'row center-xs m-0' }, exercise.deleted_date === null ? [
            !this.props.exerciseSelectionMode ? React.createElement('div', {
                'className': 'col-xs-12 p-b-2 m-0',
                'key': '50571'
            }, exercise.is_owner ? React.createElement(IconButton, {
                'tooltip': 'Edit Exercise Details',
                'icon': 'edit',
                'className': 'm-r-5 c-grey small-icon-button',
                'onClick': () => {
                    this.edit(exercise.id);
                },
                'key': '5229'
            }) : null, exercise.is_owner ? React.createElement(IconButton, {
                'tooltip': 'Hide Exercise',
                'icon': 'deactivate',
                'className': 'm-r-5 c-grey small-icon-button',
                'onClick': () => {
                    this.delete(exercise.id, 'hide');
                },
                'key': '5588'
            }) : null, React.createElement(IconButton, {
                'tooltip': 'View Exercise Details',
                'icon': 'play',
                'className': 'm-r-5 c-grey small-icon-button',
                'onClick': () => {
                    this.preview(exercise);
                }
            }), React.createElement(IconButton, {
                'className': 'col-xs-2 c-grey center-xs',
                'icon': 'info-fill',
                'tooltip': exercise.description
            }), React.createElement(IconButton, {
                'tooltip': exercise.is_favorite ? 'Unfavorite Exercise' : 'Favorite Exercise',
                'icon': exercise.is_favorite ? 'star-fill' : 'star',
                'className': exercise.is_favorite ? 'm-r-5 small-icon-button c-green' : 'm-r-5 c-grey small-icon-button',
                'onClick': () => {
                    this.toggleFavorite(exercise.id);
                }
            }), this.props.selectedClientForAction !== null ? React.createElement(IconButton, {
                'tooltip': 'Add exercise to workout cart',
                'icon': 'add',
                'className': 'm-r-5 c-black small-icon-button',
                'onClick': () => {
                    this.addExerciseToWorkoutCart();
                },
                'key': '6821'
            }) : null) : null,
            this.props.exerciseSelectionMode ? React.createElement('div', {
                'className': 'col-xs-12 p-b-2 m-0',
                'key': '50573'
            }, React.createElement(IconButton, {
                'tooltip': 'Add Exercise',
                'icon': 'add',
                'className': 'm-r-5 c-grey small-icon-button',
                'onClick': () => {
                    this.props.onExerciseSelection(exercise);
                }
            }), React.createElement(IconButton, {
                'tooltip': 'View Exercise Details',
                'icon': 'play',
                'className': 'm-r-5 c-grey small-icon-button',
                'onClick': () => {
                    this.preview(exercise);
                }
            })) : null
        ] : null, exercise.deleted_date !== null ? [exercise.is_owner ? React.createElement(IconButton, {
                'icon': 'deactivate',
                'tooltip': 'Unhide Exercise',
                'className': 'm-r-5 c-red small-icon-button',
                'onClick': () => {
                    this.delete(exercise.id, 'unhide');
                },
                'key': '79701'
            }) : null] : null));
    }
    function repeatSubCategoryOption2(category, index, subCategory, subCategoryOption) {
        return React.createElement('div', { 'className': (this.props.exerciseSelectionMode ? 'adjust-filters' : '') + ' ' + (this.props.exerciseSelectionMode && category.name == 'CAFS' ? 'w-13' : category.name == '3D Matrix' ? 'flex-25 text-left m-r-25' : 'm-r-13 ') }, React.createElement(Field, {
            'component': Checkbox,
            'name': `exercise_filter_subcategory_options['${ subCategoryOption.id }']`,
            'label': subCategoryOption.name
        }));
    }
    function repeatSubCategory3(category, index, subCategory) {
        return React.createElement('div', {
            'key': 'subCategory' + index,
            'className': 'd-flex p-10',
            'style': { alignItems: 'baseline' }
        }, React.createElement('div', { 'className': 'part-name row checkbox d-flex align-right m-l-0' }, React.createElement('label', { 'htmlFor': true }, subCategory.name)), React.createElement.apply(this, [
            'div',
            { 'className': 'd-flex flex-wrap' },
            _.map(subCategory.exercise_filter_subcategories_options, repeatSubCategoryOption2.bind(this, category, index, subCategory))
        ]));
    }
    function repeatCategory4(category, index) {
        return React.createElement.apply(this, [
            'div',
            {
                'key': 'category' + index,
                'className': 'border p-13 m-b-18'
            },
            React.createElement('div', { 'className': 'row checkbox d-flex align-right m-l-0' }, React.createElement('label', { 'htmlFor': true }, category.name)),
            _.map(category.exercise_filter_subcategories, repeatSubCategory3.bind(this, category, index))
        ]);
    }
    return React.createElement(Layout, {
        'className': 'exercise-layout',
        'style': { overflowY: this.props.exerciseSelectionMode ? 'scroll' : 'auto' }
    }, React.createElement(Panel, { 'className': 'p-r-5 full-panel exercise-preview' }, !this.props.exerciseSelectionMode ? React.createElement(Button, {
        'className': 'width-fit m-t-20 d-flex text-capital back-btn c-grey',
        'onClick': this.goToDashboard,
        'key': '2016'
    }, '\n            Back\n        ') : null, React.createElement('div', { 'className': 'row vertical-align flex-sb start-xs m-10' }, !this.props.exerciseSelectionMode ? React.createElement('div', { 'key': '2262' }, React.createElement('h3', { 'className': 'f-14 page-subtitle' }, 'Templates'), React.createElement('h2', { 'className': 'm-0 page-title' }, 'Exercises')) : null, React.createElement('div', { 'className': 'w-' + (this.props.exerciseSelectionMode ? '100' : '60') + ' program-search-box' }, React.createElement('div', { 'className': 'col-xs-12 no-box-shadow p-relative search-box  no-box-shadow m-b-20 p-5  vertical-align' }, React.createElement(Input, {
        'label': 'Search text',
        'style': {
            width: '750px',
            marginRight: '25px',
            height: '40px'
        },
        'onChange': this.onSearch,
        'onClick': this.inputClick,
        'type': 'text',
        'name': 'search'
    }), !this.props.exerciseSelectionMode ? React.createElement(Button, {
        'className': 'width-fit p-l-46 p-r-46 bg-success m-r-10',
        'onClick': this.add,
        'floating': true,
        'mini': true,
        'key': '2970'
    }, 'Add New') : null, !this.state.showFilter ? React.createElement(Button, {
        'style': { width: '45px !important' },
        'className': 'bg-primary width-41',
        'onClick': this.drawerToggle,
        'icon': 'filter_list',
        'floating': true,
        'ripple': true,
        'mini': true,
        'key': '3137'
    }) : null), this.props.selectedClientForAction !== null ? React.createElement('div', {
        'className': 'm-r-20',
        'key': '3338'
    }, React.createElement(Button, {
        'className': 'bg-accent-dark m-r-20 m-t-5',
        'onClick': this.createWorkoutFromWorkoutCart,
        'icon': 'shopping_cart',
        'floating': true,
        'ripple': true,
        'mini': true
    }, React.createElement('small', { 'className': 'notification-badge' }, this.props.workoutCartExeciseList.length))) : null)), React.createElement.apply(this, [
        'div',
        { 'className': 'row start-xs' },
        _.map(this.props.exerciseList, repeatExercise1.bind(this)),
        this.props.paginateInfo.total_pages && this.props.paginateInfo.page < this.props.paginateInfo.total_pages - 1 ? React.createElement('div', {
            'className': 'row center-xs m-30 w-100',
            'key': '8454'
        }, React.createElement('div', { 'className': 'col-xs-12' }, React.createElement(Button, {
            'label': 'Load more exercises...',
            'className': 'height-fit bg-success-border text-capital c-black',
            'onClick': () => {
                this.loadMore(true);
            }
        }))) : null
    ]), React.createElement(ExercisePreview, {
        'exercise': this.state.previewingExercise,
        'onClosePreview': () => {
            this.setState({ previewingExercise: null });
        }
    })), React.createElement(Sidebar, {
        'pinned': this.state.showFilter,
        'onOverlayClick': this.drawerToggle,
        'className': 'full-height bg-white box-shadow w-' + (this.props.exerciseSelectionMode ? 70 : 40),
        'width': this.props.exerciseSelectionMode ? 100 : 50
    }, React.createElement('form', {
        'className': 'medium-labels p-b-30',
        'style': { overflowY: 'auto' },
        'onSubmit': this.props.handleSubmit(this.applyFilter)
    }, React.createElement('div', { 'className': 'bg-accent-dark p-10 vertical-align' }, React.createElement('h5', { 'className': 'vertical-align flex-auto f-500' }, React.createElement(FontIcon, { 'value': 'filter_list' }), '\n              Filters\n        '), React.createElement(Button, {
        'onClick': () => {
            this.resetFilter();
        },
        'className': 'small-button',
        'label': 'Reset',
        'raised': true,
        'primary': true
    }), React.createElement(Button, {
        'onClick': this.applyFilter,
        'className': 'small-button m-l-10 text-capital',
        'label': 'Apply',
        'raised': true,
        'secondary': true
    }), React.createElement('span', {
        'className': 'rotate-button-icon',
        'onClick': this.drawerToggle
    }, React.createElement(FontIcon, {
        'value': 'add_circle',
        'className': 'm-l-10 pointer'
    }))), React.createElement('div', { 'className': 'filter-section p-10' }, React.createElement('div', { 'className': 'm-t-5 vertical-align w-100 m-b-10' }, React.createElement(Field, {
        'className': 'di-block m-b-0',
        'name': 'my_deleted_exercises',
        'label': 'View My Hidden Exercises',
        'component': Switch
    })), React.createElement('hr', { 'className': 'dashed' }), this.props.exerciseFilters ? React.createElement.apply(this, [
        'div',
        {
            'className': 'm-t-5 w-100 m-b-10',
            'key': '10390'
        },
        _.map(this.props.exerciseFilters, repeatCategory4.bind(this))
    ]) : null, !this.props.my_deleted_exercises ? React.createElement('div', {
        'style': { paddingBottom: '200px' },
        'key': '12125'
    }, React.createElement('div', { 'className': 'm-t-5 vertical-align w-100 m-b-10' }, React.createElement(Field, {
        'className': 'di-block m-b-0',
        'name': 'is_favorite',
        'label': 'Show Just My Favorite Exercises',
        'component': Switch
    }), React.createElement('span', { 'className': 'badge bg-success m-l-10' }, this.props.filterData.favorites ? this.props.filterData.favorites : 0)), React.createElement('hr', { 'className': 'dashed' }), React.createElement('div', { 'className': 'm-t-5 vertical-align w-100 m-b-10' }, React.createElement(Field, {
        'className': 'di-block m-b-0',
        'name': 'is_evidence_based',
        'label': 'Show Evidence Based Exercises',
        'component': Switch
    }), React.createElement('span', { 'className': 'badge bg-success m-l-10' }, _.has(this.props.filterData, 'evidence_based_count') ? this.props.filterData.evidence_based_count : 0)), React.createElement('div', { 'className': 'm-t-5 vertical-align w-100 m-b-10' }, React.createElement(Field, {
        'className': 'di-block m-b-0',
        'name': 'threed_map',
        'label': '3DMAPS',
        'component': Switch
    })), React.createElement('div', { 'className': 'm-t-5 vertical-align w-100 m-b-10' }, React.createElement(Field, {
        'className': 'di-block m-b-0',
        'name': 'gi_golf_library',
        'label': 'Golf',
        'component': Switch
    })), this.props.threed_map && !this.props.gi_golf_library ? React.createElement('div', { 'key': '13736' }, React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'performance',
        'label': 'Select Performance',
        'component': Select,
        'options': this.props.performanceData,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'optionRenderer': this.selectBadgeLessRender
    })), !(this.props.performance && this.props.performance.id == 'ANALYSIS_MOBILITY') && !(this.props.performance && this.props.performance.id == 'ANALYSIS_STABILITY') ? React.createElement('div', {
        'className': 'm-t-5',
        'key': '14318'
    }, React.createElement(Field, {
        'name': 'most',
        'label': 'Select Mobility/Stability',
        'component': Select,
        'options': this.props.mostData,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'optionRenderer': this.selectBadgeLessRender
    })) : null, React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'matrix',
        'label': 'Select Matrix',
        'component': Select,
        'options': this.props.matricData,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'optionRenderer': this.selectBadgeLessRender
    }))) : null, this.props.gi_golf_library && !this.props.threed_map ? React.createElement('div', { 'key': '15551' }, React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'id': 'gi_golf_library_level1',
        'name': 'gi_golf_library_level1',
        'label': 'Select Level 1 Filter',
        'component': Select,
        'options': this.props.golfOptions,
        'valueKey': 'id',
        'labelKey': 'name',
        'returnValue': 'object',
        'onChange': val => {
            this.giGolfFilter1Select(val);
        }
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'id': 'gi_golf_library_level2',
        'name': 'gi_golf_library_level2',
        'label': 'Select Level 2 Filter',
        'component': Select,
        'options': this.state.gi_golf_library_level2_options,
        'valueKey': 'id',
        'labelKey': 'name',
        'optionRenderer': this.selectBadgeLessRender,
        'returnValue': 'object',
        'onChange': val => {
            this.giGolfFilter2Select(val);
        }
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'id': 'gi_golf_library_level3',
        'name': 'gi_golf_library_level3',
        'label': 'Select Level 3 Filter',
        'component': Select,
        'options': this.state.gi_golf_library_level3_options,
        'valueKey': 'id',
        'labelKey': 'name',
        'returnValue': 'object',
        'optionRenderer': this.selectBadgeLessRender
    }))) : null, !this.props.threed_map && !this.props.gi_golf_library ? React.createElement('div', { 'key': '17469' }, React.createElement('h5', { 'className': 'bg-beige m-t-5 m-b-5 p-10' }, 'Exercise Sources'), React.createElement(Field, {
        'name': 'exercise_sources',
        'component': Checkboxes,
        'showCountLabel': true,
        'checked': true,
        'options': this.props.exerciseSources
    }), React.createElement('h5', { 'className': 'bg-beige m-t-5 m-b-5 p-10' }, 'Exercise Category'), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'category',
        'component': Select,
        'options': this.props.filterData.category,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'optionRenderer': this.selectRender
    })), React.createElement('h5', { 'className': 'bg-beige m-t-5 m-b-5 p-10' }, 'Additional Filters'), React.createElement('div', { 'className': 'm-t-5' }, this.props.category && this.props.category === 5 ? React.createElement(Field, {
        'name': 'sports',
        'label': 'Sports Name',
        'component': Select,
        'options': this.props.filterData.sports,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'multi': true,
        'optionRenderer': this.selectRender,
        'key': '18530'
    }) : null), React.createElement('div', { 'className': 'm-t-5' }, this.props.category && this.props.category === 5 ? React.createElement(Field, {
        'name': 'sports_exercise_type',
        'label': 'Sports Condition',
        'component': Select,
        'options': this.props.filterData.sports_exercise_type,
        'valueKey': 'id',
        'labelKey': 'name',
        'clearable': true,
        'optionRenderer': this.selectRender,
        'key': '19128'
    }) : null), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'metric',
        'label': 'Exercise Metric',
        'component': Select,
        'options': this.props.filterData.metric,
        'multi': true,
        'clearable': true,
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'body_part',
        'label': 'Body Part',
        'component': Select,
        'options': this.props.filterData.body_part,
        'valueKey': 'id',
        'labelKey': 'name',
        'multi': true,
        'clearable': true,
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'difficulty',
        'label': 'Difficulty Level',
        'component': Select,
        'options': this.props.filterData.difficulty,
        'multi': true,
        'clearable': true,
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'cues',
        'label': 'Cues',
        'component': Select,
        'options': this.props.filterData.cues,
        'multi': true,
        'clearable': true,
        'valueKey': 'id',
        'labelKey': 'name',
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'equipments',
        'label': 'Equipment',
        'component': Select,
        'options': this.props.filterData.equipments,
        'multi': true,
        'clearable': true,
        'valueKey': 'id',
        'labelKey': 'name',
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'exercise_types',
        'label': 'Types',
        'component': Select,
        'options': this.props.filterData.exercise_types,
        'multi': true,
        'clearable': true,
        'valueKey': 'id',
        'labelKey': 'name',
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'action',
        'label': 'Action',
        'component': Select,
        'options': this.props.filterData.action,
        'multi': true,
        'clearable': true,
        'valueKey': 'id',
        'labelKey': 'name',
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'driver',
        'label': 'Driver',
        'component': Select,
        'options': this.props.filterData.driver,
        'multi': true,
        'clearable': true,
        'valueKey': 'id',
        'labelKey': 'name',
        'optionRenderer': this.selectRender
    })), React.createElement('div', { 'className': 'm-t-5' }, React.createElement(Field, {
        'name': 'direction',
        'label': 'Direction',
        'component': Select,
        'options': this.props.filterData.direction,
        'multi': true,
        'clearable': true,
        'valueKey': 'id',
        'labelKey': 'name',
        'optionRenderer': this.selectRender
    }))) : null) : null))));
}