import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/homeFit_tab_content";
import React  from 'react';
import { reduxForm,formValueSelector, change } from 'redux-form';
import PropTypes from 'prop-types';
import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {listContent, hideContent, toggleExerciseFavorite} from '../../redux/content/network/content';
import {listHomeFitContent,getCategoryHomeFit, hideHomefitContent, listTrainerContent, deleteHomeFitContent, homefitContentSearch } from '../../redux/content/network/homefit_content';
import ExerciseActionCreator from '../../redux/content/actions/exercise_list';
import Utils from '../../utils/utils';
import {history} from "../../redux/store";
import Swal from 'sweetalert2';
import HelpVideoCreator from '../../redux/core/actions/help_video_creator';
import { EXERCISE_LIST_VIDEO } from '../../utils/help_video';
import ClientAction from '../../redux/engagement/actions/client_creator';
import { createWorkoutFromWorkoutCart } from '../../redux/program/network/program_list';



let performanceData = [
    { "id": "ANALYSIS_MOBILITY", name: "Mobility" },
    { "id": "ANALYSIS_STABILITY", name: "Stability" },
    { "id": "PERFORMANCE_SUPPORT", name: "Performance - Support" },
    { "id": "PERFORMANCE_ELEVATED_LUNGELEG", name: "Performance - Elevated (Lunge Leg)" },
    { "id": "PERFORMANCE_FIXEDTRUNK", name: "Performance - Fixed Trunk" },
    { "id": "PERFORMANCE_UNILATERALHANDSWING", name: "Performance - Unilateral Hand Swing" },
    { "id": "PERFORMANCE_PLANE_HANDS", name: "Performance - Plane (Hands)" },
    { "id": "PERFORMANCE_PLANE_FOOT", name: "Performance - Plane (Foot)" },
    { "id": "PERFORMANCE_HYBRID_HANDS", name: "Performance - Hybrid (Hands)" },
    { "id": "PERFORMANCE_HYBRID_FOOT", name: "Performance - Hybrid (Foot)" },
    { "id": "PERFORMANCE_PIVOT_IN_CHAIN", name: "Performance - Pivot (In-Chain)" },
    { "id": "PERFORMANCE_PIVOT_OUT_OF_CHAIN", name: "Performance - Pivot (Out-of-Chain)" },
    { "id": "PERFORMANCE_LOAD", name: "Performance - Load" },
    { "id": "PERFORMANCE_ELEVATED_STANCELEG", name: "Performance - Elevated (Stance Leg)" },
    { "id": "PERFORMANCE_LOCOMOTOR", name: "Performance - Locomotor" },
    { "id": "PERFORMANCE_SPHERICAL", name: "Performance - Spherical" }
];

let mostData = [
    { "id": "MOBILITY", name: "Mobility" },
    { "id": "STABILITY", name: "Stability" }
];

let matricData = [
    { "id": "ANT", name: "ANT" },
    { "id": "PST", name: "PST" },
    { "id": "SSL", name: "SSL" },
    { "id": "OSL", name: "OSL" },
    { "id": "SSR", name: "SSR" },
    { "id": "OSR", name: "OSR" }
];


class ContentVideos extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
        this.unpublish = this.unpublish.bind(this);
        this.preview = this.preview.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.setFilterPreferences = this.setFilterPreferences.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.onSearch = _.debounce(this.onSearch.bind(this),1000);
        this.inputClick = this.inputClick.bind(this);
        this.setActive = this.setActive.bind(this);
        this.state = {
            search:"",
            // filterPreferences:{first:true},
            previewingExercise: null,            
            exercise_library_to_be_loaded: null,
            searchCategory: [],
        };
    }
    setActive(el, active){
        const formField = el.parentNode.parentNode
        if (active) {
          formField.classList.add('form-field--is-active')
        } else {
          formField.classList.remove('form-field--is-active')
          el.value === '' ? 
            formField.classList.remove('form-field--is-filled') : 
            formField.classList.add('form-field--is-filled')
        }
    }
    inputClick(el){
        [].forEach.call(
            document.querySelectorAll('.theme__inputElement___27dyY'),
            (el) => {
              el.onblur = () => {
                this.setActive(el, false)
              }
              el.onfocus = () => {
                this.setActive(el, true)
              }
            }
          )
    }
    componentDidMount() {
        this.loadMore(false,true);
        this.props.getCategoryHomeFit({
            app_version: "5.2",
            current_role_type: "practice_admin",
            device_type: "web",
            package_version: "2",
            practice_id: this.props.practice_id
        });
        // this.fetchFilterData();      
        this.props.updateHelpVideo(EXERCISE_LIST_VIDEO);
    }

    componentWillReceiveProps(nextProps) {
        if(!_.isEmpty(this.props.current_filters) &&  !_.isEqual(this.props.current_filters,nextProps.current_filters)){
            this.setFilterPreferences(nextProps.current_filters);
        }
    }


    applyFilter(){
        this.loadMore();
    }

    
    loadMore(increment=false,first=false) {
        let params = { 
            "package_version": "2",
            "app_version": "5.2",
            "device_type": "web",
            "content_type": this.props.content_type,
            ...this.props.paginateInfo,
            ...this.state.filterPreferences,
        };
        if(increment){
            params.page = params.page + 1;
        }else{
            params.page = 0;
        }
        if(!this.state.filterPreferences){
            if(this.props.content=='gi'){
                this.props.listContent(params);
            } else if(this.props.content=='homefit'){
                console.log('loadmore')
                params.practice_id= this.props.practice_id;
                params.current_role_type= "practice_admin";
                if(this.props.user.gi_isadmin==false){
                    params.app_version = "5.2"
                    params.package_version = "2"
                    params.my_content = true;
                    this.props.listHomeFitContent(params);
                } else {
                    params.my_content = true;
                    this.props.listHomeFitContent(params);
                }
            }
        }
    }
    
    setFilterPreferences(filterData) {
        let params = {
            "search" : filterData.search,
            app_version: "5.2",
            current_role_type: "practice_admin",
            package_version: "2",
            practice_id: this.props.practice_id,
            my_content: true,
            content_type: this.props.content_type,
            page: 0,
            category: [filterData.category]
        };
        this.setState({filterPreferences:params},()=>{
            this.props.listHomeFitContent(params);
        });
    }



    // fetchFilterData(){
    //     this.props.homefitContentSearch(...this.state.filterPreferences);
    // }


    preview(exercise) {
        this.setState({
            previewingExercise: exercise,
        });
    }

    delete(id, toggle_visibility){
        let that = this;
        let display_text = "";
        if(toggle_visibility === "hide") {
            display_text = `Are you sure you want to hide this ${this.props.content_type}`;
        } else {
            display_text = `Are you sure you want to unhide this ${this.props.content_type}`;
        }
        Swal.fire({
            title: "Are you sure?",
            text: display_text,
            type: "info",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "#0292d3",
            cancelButtonColor: "#4C555C",
            confirmButtonText: "Yes, I am sure!",
            cancelButtonText: "No",
            useRejections:true
        }).then(function (willDelete) {   
            console.log("willDelete",willDelete);  
            if (willDelete.value) {       
                that.props.deleteHomeFitContent({
                    "package_version": "2",
                    "app_version": "5.2",
                    "device_type": "web",
                    "id": id,
                    "toggle_visibility": toggle_visibility,
                }).then(()=>{
                    that.props.listHomeFitContent({
                        "package_version": "2",
                        "app_version": "5.2",
                        "device_type": "web",
                        "content_type": this.props.content_type,
                        "my_content": true
                    });
                }).catch((err)=>{console.log(err)});
            }    
        }, function (dismiss) {
                     console.log('log')   
        });
    }

    onSearch(text){

        if(text == null){
            this.loadMore();
        }
        this.setState({search:text},()=>{
                this.loadMore(false,true);

        });

    }

    add(){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/engagement/content/"+this.props.content_type+"/add");
    }

    edit(id){
        history.push("/practice/"+this.props.selectedRole.practice.id+"/engagement/content/"+this.props.content_type+"/edit/"+id);
    }
    unpublish(content){
        let params = {
            "id": content.id,
            "visibility_type": content.published ? 'hide': 'unhide',
            "package_version": "2",
            "app_version": "5.2",
            "device_type": "web",
        }
        if(this.props.content=='gi'){
            this.props.hideContent(params).then(()=> {this.loadMore()})
        } else if(this.props.content=='homefit'){
            this.props.hideHomefitContent(params)
            .then(()=> {
                this.props.listHomeFitContent({
                    app_version: "5.2",
                    content_type: this.props.content_type,
                    current_role_type: "practice_admin",
                    device_type: "web",
                    my_content: true,
                    package_version: "2",
                    practice_id: this.props.practice_id,
                });
            })
            .catch((err)=>{
                console.log(err,"err")
            })
        }
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
        this.props.clean();
        this.props.clearHelpVideo();
        this.props.clearClientSelection();
        // this.props.clearWorkoutCartActionCreator();
    }

}

ContentVideos.propTypes = {
    exerciseSelectionMode: PropTypes.bool,
    onExerciseSelection: PropTypes.func,
};
ContentVideos.defaultProps = {
    exerciseSelectionMode: false,
    onExerciseSelection: () => { },
};
const selector = formValueSelector('ContentFilterForm');
function mapDispatchToProps(dispatch) {
    return (
        bindActionCreators({
            hideHomefitContent,
            hideContent,
            getCategoryHomeFit,
            deleteHomeFitContent,
            listContent,
            listTrainerContent,
            listHomeFitContent,
            toggleExerciseFavorite,
            homefitContentSearch,
            clean: ExerciseActionCreator.cleanModule,
            incrementPageCount: ExerciseActionCreator.incrementPageCount,
            updateHelpVideo: HelpVideoCreator.updateHelpVideo,
            clearHelpVideo: HelpVideoCreator.clearHelpVideo,
            clearClientSelection: ClientAction.clientSelectionClear,
            // clearWorkoutCartActionCreator: ExerciseActionCreator.clearWorkoutCart,
            change,
        }, dispatch)
    );
}

function mapStateToProps(state, ownProps) {    
    let exerciseSources = [];
    let filterData = state.exercise.exerciseList.filterData;
    let searchPreferences = state.exercise.exerciseList.searchPreferences;
    
    let initialValues = {
        exercise_sources:[],
        category:null,
        direction:[],
        cues:[],
        sports_exercise_type:[],
        difficulty:[],
        body_part:[],
        metric:[],
        driver:[],
        equipments:[],
        action:[],
        is_favorite: _.has(searchPreferences, "is_favorite") ? searchPreferences.is_favorite : false,
        is_evidence_based:_.has(searchPreferences,"is_evidence_based")  ? searchPreferences.is_evidence_based : false,
    };

    return {
        performanceData,
        mostData,
        matricData,
        exerciseList: state.exercise.exerciseList.exerciseList,
        user: state.auth.authReducer.user,
        contentList : state.content.content.contentList,
        homeFitContentList : state.content.content.homeFitContentList,

        paginateInfo : state.content.content.paginateInfo,
        categories: state.content.content.categories,
        practice_id: state.auth.roleReducer.selectedRole.practice.id,
        selectedRole: state.auth.roleReducer.selectedRole,
        is3DMAPSEnabled: state.auth.roleReducer.selectedRole.is_3dmaps_enabled,
        isGIGolfEnabled: state.auth.roleReducer.selectedRole.is_gi_golf_enabled,
        selectedClientForAction: state.engagement.clientReducer.selected_client_for_action,
        filterData: state.content.content.filterData,
        exerciseSources:exerciseSources,
        initialValues:initialValues,
        my_deleted_exercises: selector(state, 'my_deleted_exercises'),
        performance: selector(state, 'performance'),
        category: selector(state, 'category'),
        exercise_sources: selector(state, 'exercise_sources'),
        current_filters: selector(
            state,
            'category',
            'search'
    ),
        ...ownProps,
    };
}

const validate = (values, props) => {
    let requiredFields = [
        'category',
    ];
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const ContentListFilterForm = reduxForm(
    {
        form: 'ContentFilterForm',
        enableReinitialize: true,
        validate
    }
)(ContentVideos);

const ContentVideosConnected = connect(mapStateToProps,mapDispatchToProps)(ContentListFilterForm);
export default ContentVideosConnected;

