import React from "react";
import BaseComponent from '../../utils/common_components/basecomponent';
import Template from "./templates/profile_edit_info";
import { formValueSelector, reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import store from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../redux/user/actions/user_profile';
import { postProfileProfessionalInfo } from '../../redux/user/network/user_profile';
import Utils from '../../utils/utils';
import { history } from '../../redux/store';
import { verifyToken } from '../../redux/auth/network/auth_network';
import location from "../../../assets/map-marker.png"
import { GoogleMap, Marker, Autocomplete } from '@react-google-maps/api';
import TextInput from "../../utils/common_components/textInput_form"
import { TextField } from "@mui/material";
import Button from "react-toolbox/lib/button"


const containerStyle = {
    width: '100%',
    height: '400px',
    marginBottom: '20px'
};

// Pune coordinates
const initialCoordinates = {
    lat: 40.730610,
    lng: -73.935242
};

// Initial address details for Pune
const initialLocation = {
    country: 'United States',
    state: 'New York',
    city: 'New York',
    pincode: '13090'
};

let select_options = [
    {
        "id": 1,
        "label": "Physical Therapist",
        "value": 'Physical Therapist',
    },
    {
        "id": 2,
        "label": "Personal Trainer",
        "value": "Personal Trainer",
    },
    {
        "id": 3,
        "label": "Strength Coach",
        "value": "Strength Coach",
    },
    {
        "id": 4,
        "label": "Holistic Practitioner",
        "value": "Holistic Practitioner",
    },
    {
        "id": 5,
        "label": "Athletic Trainer",
        "value": "Athletic Trainer",
    },
    {
        "id": 6,
        "label": "Other",
        "value": "Other",
    }
]
let areas_of_interest = [
    {
        "id": 1,
        "name": "Injury Prevention" || "Injury prevention",
        "can_be_deleted": false
    },
    {
        "id": 2,
        "name": "Reconditioning from injury",
        "can_be_deleted": false
    },
    {
        "id": 3,
        "name": "Performance training",
        "can_be_deleted": false
    },
    {
        "id": 4,
        "name": "Health & wellness",
        "can_be_deleted": false
    },
]

class ProfileEditInfo extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.doSave = this.doSave.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.onChangeInterest = this.onChangeInterest.bind(this);
        this.onChangeContent = this.onChangeContent.bind(this);
        this.onCheckBoxValue = this.onCheckBoxValue.bind(this);
        this.state = {
            checkvalue: false,
            interestValue: {},
            occupationValue: "",
            location: initialLocation,
            markerPosition: initialCoordinates,
            showPopup: false,
            pincodeValue: initialLocation.pincode,
            googleMapsLoaded: false,
            storeData: initialLocation,
        }
        this.mapRef = null; // Initialize mapRef to null
    }


    componentDidMount() {
        this.setState({
            checkvalue: this.props.initialValues?.checkvalue ? this.props.initialValues?.consented_coach_locator : this.state.checkvalue,
            interestValue: this.props.initialValues?.areas_of_interest ? this.props.initialValues?.areas_of_interest : this.state.interestValue,
            occupationValue: this.props.initialValues?.user_type ? this.props.initialValues?.user_type.id  : this.state.occupationValue
        })
        if (!this.props.initialValues?.longitude || !this.props.initialValues?.latitude) {
            this.setState({
                markerPosition: initialCoordinates,
                location: initialLocation,
                storeData: initialLocation
            })
        }
        else {
            this.setState({
                markerPosition: { lat: this.props.initialValues?.latitude, lng: this.props.initialValues?.longitude },
                location: {
                    country: this.props.initialValues?.country,
                    state: this.props.initialValues?.state,
                    city: this.props.initialValues?.city,
                    pincode: this.props.initialValues?.zipcode
                },
                storeData: {
                    country: this.props.initialValues?.country,
                    state: this.props.initialValues?.state,
                    city: this.props.initialValues?.city,
                },
                pincodeValue: this.props.initialValues?.zipcode
            })
        }

    }

    onCheckBoxValue(val) {
        this.setState({
            checkvalue: val
        })
    }

    doSave(data) {
        let myobj = {
            consented_coach_locator: this.state.checkvalue,
        }
        let newData = { ...myobj, ...data }
        let params = Utils.flatternFormData(newData);
        params.is_client = this.props.isClient;

        let newDataIntrest = [...this.state.interestValue.map(el => el.name)]

        this.props.postProfileProfessionalInfo({
            ...newData, id: this.props.user.id,
            areas_of_interest: newDataIntrest,
            zipcode: this.state.pincodeValue,
            country: this.state.storeData.country,
            state: this.state.storeData.state,
            city: this.state.storeData.city,
            latitude: this.state.markerPosition.lat,
            longitude: this.state.markerPosition.lng,
            user_type: this.state.occupationValue
        }).then(() => {
            this.props.verifyToken({
                package_version: 2,
            });
        }).catch(() => { });


    }

    doCancel() {
        history.goBack();
    }

    onChangeInterest(value) {
        console.log(value, 'value');
        this.setState({
            interestValue: value
        })
    }

    onChangeContent(value) {
        this.setState({
            occupationValue: value
        })
    }

    onMapClick = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        this.setState({ markerPosition: { lat, lng } });
        this.geocodeLatLng(lat, lng);
    };

    geocodeLatLng = (lat, lng) => {
        const geocoder = new window.google.maps.Geocoder();
        const latlng = { lat, lng };
        geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const addressComponents = results[0].address_components;
                    this.setState({
                        location: {
                            country: this.getAddressComponent(addressComponents, 'country'),
                            state: this.getAddressComponent(addressComponents, 'administrative_area_level_1'),
                            city: this.getAddressComponent(addressComponents, 'locality'),
                            pincode: this.getAddressComponent(addressComponents, 'postal_code')
                        }
                    });
                } else {
                    alert('No results found');
                }
            } else {
                alert('Geocoder failed due to: ' + status);
            }
        });
    };

    getAddressComponent = (components, type) => {
        const component = components.find(component => component.types.includes(type));
        return component ? component.long_name : '';
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'zip-code') {
            this.setState(prevState => ({
                location: {
                    ...prevState.location,
                    pincode: value
                }
            }));
        }
    };

    handleSave = () => {
        this.setState({
            showPopup: false,
            pincodeValue: this.state.location.pincode,
            storeData: this.state.location
        });
    };

    handleSelect = (place) => {
        const position = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        };
        const addressComponents = place.address_components;
        this.setState({
            markerPosition: position,
            location: {
                country: this.getAddressComponent(addressComponents, 'country'),
                state: this.getAddressComponent(addressComponents, 'administrative_area_level_1'),
                city: this.getAddressComponent(addressComponents, 'locality'),
                pincode: this.getAddressComponent(addressComponents, 'postal_code')
            }
        });

        if (this.mapRef) {
            this.mapRef.panTo(position);
            this.mapRef.setZoom(15); // Adjust the zoom level as needed
        }
    };

    onLoad = (map) => {
        const position = {
            lat: this.state.markerPosition.lat,
            lng: this.state.markerPosition.lng
        };
        this.mapRef = map; // Assign the map instance to this.mapRef
        this.mapRef.panTo(position);
        this.mapRef.setZoom(12);
    };

    showPopup = () => {

        loadGoogleMapsApi('AIzaSyC7X5wNI6r_ZAa7wKW7vpi_8sE4Fg_TQXo', ['places'])
            .then(() => {
                this.setState({
                    showPopup: true,
                    googleMapsLoaded: true,
                });
            })
            .catch((error) => {
                console.error('Error loading Google Maps API:', error);
            });
    };

    closePopup = () => {
        this.setState({ showPopup: false });
    };


    AddYourLocation = () => {
        return (
            <>
                <div className="location-container">
                    <div class='location-sec'>
                        <span>Your location</span>
                        <a onClick={this.showPopup}>Change Location</a>
                    </div>
                    <div class="sub-con">
                        <img src={location} alt="location" />
                        <div class="title-con">
                            <span class="con-subtitle">Location</span>
                            <span class="con-title">{this.state.storeData.country}, {this.state.storeData.state}, {this.state.storeData.city} {this.state.pincodeValue} </span>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderMap = () => {
        return (
            <>
                {this.state.showPopup &&
                    <>
                        <div class='popup-map'>
                        <Autocomplete class="auto-complete"
                                onLoad={autocomplete => {
                                    this.autocomplete = autocomplete;
                                }}
                                onPlaceChanged={() => {
                                    if (this.autocomplete !== null) {
                                        const place = this.autocomplete.getPlace();
                                        if (place?.geometry) {
                                            this.handleSelect(place);
                                        } else {
                                            console.log("Place not found");
                                        }
                                    } else {
                                        console.log("Autocomplete is not loaded yet!");
                                    }
                                }}>
                                <TextField style={{ width: '350px', marginBottom: '30px', display: 'flex' }}
                                    type="text"
                                    size='small'
                                    label="Search places..."
                                />
                            </Autocomplete>
                            <div className="popup-sec">
                                <TextField name="country" type="text" size="small" disabled={true} value={this.state.location.country} label="Country" />
                                <TextField name="state" type="text" size="small" disabled={true} value={this.state.location.state} label="State" />
                                <TextField name="city" type="text" size="small" disabled={true} value={this.state.location.city} label="City" />
                                <TextField name="zip-code" type="text" size="small"
                                    onChange={this.handleInputChange} value={this.state.location.pincode} label="Pin Code (Update, if needed)" />
                            </div>
                            {this.state.googleMapsLoaded && (
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={initialCoordinates}
                                    zoom={12}
                                    onClick={this.onMapClick}
                                    onLoad={this.onLoad}
                                >
                                    <Marker position={this.state.markerPosition} />
                                </GoogleMap>
                            )}
                            <button label="Save" onClick={this.handleSave} class="map-btn" style={{ width: '100%' }}>Save</button>
                        </div>
                        <div className='overlay-popup' onClick={this.closePopup}></div>
                    </>
                }
            </>
        )
    }

    render() {
        return Template.apply(this);
    }

    componentWillUnmount() {
    }

}

const selector = formValueSelector('ProfileEditInfoForm');

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        verifyToken,
        postProfileProfessionalInfo
    }, dispatch);
}

function mapStateToProps(state, ownProps) {
    let selectedRole = state.auth.roleReducer.selectedRole;
    let profile = { ...state.user.userProfile.profile };
    return {
        isClient: ownProps.is_client ? ownProps.is_client : !_.isEmpty(selectedRole) && selectedRole.role.name === 'client',
        supportingData: state.user.userProfile.supportingData,
        initialValues: profile,
        user_guid: selector(state, 'user_guid'),
        select_options,
        areas_of_interest,
        user: state.auth.authReducer.user,
        ...ownProps,
    };
}

ProfileEditInfo.propTypes = {
    self_editing: PropTypes.bool,
};
ProfileEditInfo.defaultProps = {
    self_editing: true,
};


const ProfileEditInfoForm = reduxForm(
    {
        form: 'ProfileEditInfoForm',
        enableReinitialize: true,
    }
)(ProfileEditInfo);

const ProfileEditInfoConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileEditInfoForm);

export default ProfileEditInfoConnected;



// utils/loadGoogleMapsApi.js
let googleMapsScriptLoaded = false;

export function loadGoogleMapsApi(apiKey, libraries = []) {
    return new Promise((resolve, reject) => {
        if (googleMapsScriptLoaded) {
            resolve();
            return;
        }

        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries.join(',')}`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
            googleMapsScriptLoaded = true;
            resolve();
        };
        script.onerror = (e) => {
            reject(e);
        };

        document.head.appendChild(script);
    });
}
