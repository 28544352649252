import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Select from '../../../utils/common_components/select';
import Checkbox from '../../../utils/common_components/checkbox_form';
import Checkboxes from '../../../utils/common_components/checkbox_group_form';
import Info from '../../../../assets/info.png';
export default function () {
    return React.createElement('div', { 'className': 'row center-profile' }, React.createElement(Button, {
        'className': 'width-fit m-t-20 d-flex text-capital back-btn c-grey',
        'onClick': this.doCancel
    }, '\n        Back\n    '), React.createElement('form', {
        'className': 'max-con',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement(Card, { 'className': 'w-100 card-bg no-box-shadow' }, React.createElement('div', { 'className': 'bg-white box-shadow-10 p-24' }, React.createElement('span', { 'className': 'title' }, 'Edit Professional Info'), React.createElement(CardActions, {
        'style': {
            display: 'flex',
            flexDirection: 'column'
        },
        'className': 'end-xs m-r-10 m-b-20 m-t-30'
    }, React.createElement('div', { 'style': { width: '100%' } }, React.createElement(Field, {
        'component': Select,
        'name': 'user_type',
        'valueKey': 'id',
        'labelKey': 'label',
        'onChange': this.onChangeContent,
        'label': 'Are you a',
        'value': this.state.occupationValue,
        'options': this.props.select_options
    })), React.createElement('div', {
        'className': 'areas-interests',
        'style': {
            width: '100%',
            marginBottom: '15px'
        }
    }, React.createElement('p', { 'style': { textAlign: 'left' } }, 'Your areas of interests'), React.createElement('div', {
        'style': {
            display: 'flex',
            flexDirection: 'column'
        }
    }, React.createElement('div', { 'style': { textAlign: 'left' } }, React.createElement(Field, {
        'name': 'areas_of_interest',
        'onChange': this.onChangeInterest,
        'value': this.state.interestValue,
        'edit': true,
        'component': Checkboxes,
        'options': this.props.areas_of_interest,
        'colXSNum': 10,
        'column': true + '/'
    })))), React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'name': 'company_name',
        'type': 'text',
        'component': TextInput,
        'maxLength': '300',
        'label': 'Add Company Name'
    }), React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'name': 'bio',
        'type': 'text',
        'component': TextInput,
        'maxLength': '300',
        'label': 'Add Bio'
    }), '\n                                ', this.AddYourLocation(), '\n                            ', React.createElement('span', {
        'className': 'con-subtitle',
        'style': {
            position: 'relative',
            top: '-11px',
            marginRight: 'auto'
        }
    }, 'Max 300 characters'), React.createElement('div', { 'className': 'alert-box-con' }, React.createElement('span', { 'className': 'alert-header' }, 'Get found by clients searching for someone like you.'), React.createElement('ul', {}, React.createElement('li', {}, 'Movement Professional Locator makes it easy for clients to find you, so you can focus on what you do best: training and helping others reach their goals.\n                                    ', React.createElement('li', {}, 'Get referrals from other GI movement professional for clients who need your expertise.\n                                '))), React.createElement('div', {
        'className': 'note',
        'style': {
            display: 'flex',
            alignItems: 'flex-start',
            gap: '8px',
            marginBottom: '20px',
            marginTop: '20px'
        }
    }, React.createElement('img', {
        'src': Info,
        'alt': 'info'
    }), React.createElement('span', { 'className': 'alert-info' }, 'You should have passed at least one certification or specialization')), React.createElement('div', { 'className': 'check-btn' }, React.createElement(Field, {
        'className': 'w-100 p-t-0 coach-label',
        'name': 'consented_coach_locator',
        'onChange': this.onCheckBoxValue,
        'component': Checkbox,
        'label': 'Subscribe me on a Movement Professional Locator'
    }))), React.createElement(Button, {
        'label': 'Save',
        'disabled': this.props.submitting,
        'className': 'bg-success btn-width',
        'type': 'submit',
        'primary': true,
        'raised': true
    }))))), '\n      ', this.renderMap(), '\n');
}