import * as React from 'react';
import * as _ from 'lodash';
import { Card } from 'react-toolbox/lib/card';
import { CardActions } from 'react-toolbox/lib/card';
import { CardHeader } from 'react-toolbox/lib/card';
import { CardTitle } from 'react-toolbox/lib/card';
import { CardText } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button';
import TextInput from '../../../utils/common_components/textInput_form';
import { Field } from 'redux-form';
import Select from '../../../utils/common_components/select';
import Switch from '../../../utils/common_components/switch_form';
import { Avatar } from 'react-toolbox/lib/avatar';
import Dropzone from '../../../utils/common_components/dropzone';
import Checkbox from '../../../utils/common_components/checkbox_form';
import Checkboxes from '../../../utils/common_components/checkbox_group_form';
import IconButton from '../../../utils/common_components/tooltipIconButton';
import VimeoUploader from '../../../utils/common_components/vimeo_uploader';
import VimeoPlayer from '../../../utils/common_components/vimeo_player';
import RichTextEditor from '../../../utils/common_components/rich_text_editor_form.jsx';
import DatePicker from '../../../utils/common_components/datepicker_form';
import TimePicker from '../../../utils/common_components/time_picker_form';
import { FieldArray } from 'redux-form';
export default function () {
    function repeatSubCategoryOption1(category, index, subCategory, subCategoryOption) {
        return React.createElement('div', { 'className': category.name == '3D Matrix' ? 'flex-25 text-left' : 'm-r-40' }, React.createElement(Field, {
            'component': Checkbox,
            'name': `exercise_filter_subcategory_options['${ subCategoryOption.id }']`,
            'label': subCategoryOption.name
        }));
    }
    function repeatSubCategory2(category, index, subCategory) {
        return React.createElement('div', {
            'key': 'subCategory' + index,
            'className': 'd-flex p-10',
            'style': { alignItems: 'baseline' }
        }, React.createElement('div', { 'className': 'part-name row checkbox d-flex align-right m-l-0' }, React.createElement('label', { 'htmlFor': true }, subCategory.name)), React.createElement.apply(this, [
            'div',
            { 'className': 'd-flex flex-wrap' },
            _.map(subCategory.exercise_filter_subcategories_options, repeatSubCategoryOption1.bind(this, category, index, subCategory))
        ]));
    }
    function repeatCategory3(category, index) {
        return React.createElement.apply(this, [
            'div',
            {
                'key': 'category' + index,
                'className': 'border p-13 m-b-18'
            },
            React.createElement('div', { 'className': 'row checkbox d-flex align-right m-l-0' }, React.createElement('label', {
                'className': 'f-700',
                'htmlFor': true
            }, category.name)),
            _.map(category.exercise_filter_subcategories, repeatSubCategory2.bind(this, category, index))
        ]);
    }
    return React.createElement('div', { 'className': 'row exercise-edit center-xs m-t-20' }, React.createElement(Card, { 'className': 'no-box-shadow m-20 p-0' }, React.createElement(CardText, { 'className': 'p-0' }, React.createElement('form', {
        'className': 'd-flex no-box-shadow',
        'onSubmit': this.props.handleSubmit(this.doSave)
    }, React.createElement('div', { 'className': 'w-70 media-info p-b-36' }, React.createElement(Card, { 'className': 'add-video bg-white box-shadow p-t-24 p-b-36 p-l-24 p-r-24 m-r-20' }, React.createElement(Card, { 'className': 'no-box-shadow bg-white' }, React.createElement(CardTitle, {
        'title': (this.props.match.params.exercise_id ? 'Edit' : 'Add') + ' New Exercise',
        'className': 'c-black form-title f-24 w-100'
    }), React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'label': 'Exercise Name',
        'name': 'name',
        'onClick': this.inputClick,
        'type': 'text',
        'component': TextInput
    }), React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'name': 'description',
        'type': 'text',
        'component': TextInput,
        'label': 'Description',
        'multiline': true
    }), !this.props.video_guid ? React.createElement(CardTitle, {
        'title': 'Video',
        'className': 'c-black video-title form-subtitle f-18 m-t-15 w-100',
        'key': '2888'
    }) : null, React.createElement('div', { 'className': 'p-relative m-t-20' }, this.props.video_guid ? React.createElement(Card, {
        'className': 'no-box-shadow',
        'key': '3100'
    }, React.createElement('div', { 'className': 'row m-t-10 center-xs' }, React.createElement('div', { 'className': 'm-t-20 col-xs-6' }, React.createElement(CardTitle, {
        'title': 'Video',
        'className': 'c-black video-title form-subtitle f-18 m-b-8 w-100'
    }), React.createElement(VimeoPlayer, {
        'identifier': 'gomotive-wisita-imagecreator',
        'handleUploadProcessing': true,
        'onPlayerInitialized': player => {
            this.player = player;
        },
        'videoList': [this.props.video_guid]
    })), React.createElement('div', { 'className': 'col-xs-6 center-xs' }, React.createElement(CardTitle, {
        'title': 'Thumbnail',
        'className': 'c-black f-14'
    }), React.createElement('div', { 'className': 'f-12 c-grey m-t-8 m-b-8 text-left' }, '\n                                                Select Play on the video and pause on the desired frame.\n                                                Then select "Update Thumbnail".\n                                            '), React.createElement('div', { 'className': 'row' }, React.createElement('div', { 'className': 'col-xs-12' }, this.props.exercise_thumbnail_url ? React.createElement(Card, {
        'className': 'bg-white p-0 m-l-5 m-r-5 m-b-20',
        'key': '4608'
    }, React.createElement('div', {
        'className': 'thumbnail h-200',
        'style': { backgroundImage: 'url(' + encodeURI(this.props.exercise_thumbnail_url) + ')' }
    })) : null, React.createElement(Button, {
        'className': 'm-b-20 bg-success p-l-18 p-r-18 f-14',
        'label': 'Update Thumbnail',
        'onClick': this.captureThumbnail,
        'primary': true,
        'raised': true
    })))))) : null, React.createElement('div', { 'className': !this.props.video_guid ? 'p-relative d-flex p-t-30 p-b-30' : 'w-50 p-relative d-flex p-t-30 top-100-neg' }, React.createElement('div', { 'className': 'preview-container d-flex w-100' }, React.createElement('div', { 'className': 'row video-btn-container' }, React.createElement(VimeoUploader, {
        'className': 'col w-100 m-l-4 add-media-btn',
        'onUploadCompleted': this.onVideoUploaded,
        'onUploadCanceled': () => {
        },
        'onUploadFailed': this.onVideoUploadFailed,
        'name': 'add_media',
        'buttonText': this.props.video_guid ? 'Add/Change Video' : 'Add Media'
    })))), React.createElement('div', { 'className': 'flex-space-between' }, React.createElement('div', { 'className': 'w-48' }, React.createElement(Field, {
        'name': 'metric',
        'component': Select,
        'label': 'Exercise Metric',
        'options': this.props.supportingData.metric,
        'clearable': false
    })), React.createElement('div', { 'className': 'w-48' }, React.createElement(Field, {
        'name': 'category',
        'component': Select,
        'label': 'Category',
        'options': this.props.supportingData.category,
        'valueKey': 'id',
        'labelKey': 'name'
    }))), React.createElement('div', { 'className': 'row checkbox d-flex align-right m-l-0' }, React.createElement(Field, {
        'className': 'm-r-10',
        'name': 'is_client_specific',
        'component': Checkbox
    }), React.createElement('label', { 'className': 'f-16 checkbox-label material-label m-r-20' }, 'Client Specific Exercise?')), this.props.is_client_specific ? React.createElement('div', { 'key': '8294' }, React.createElement(Field, {
        'name': 'client',
        'component': Select,
        'label': 'Select Client',
        'options': this.props.supportingData.client,
        'labelKey': 'email',
        'valueKey': 'id'
    })) : null))), React.createElement.apply(this, [
        Card,
        { 'className': 'filters p-t-24 p-b-36 p-l-24 p-r-24 box-shadow border-r-10' },
        React.createElement(CardTitle, {
            'title': 'Filters',
            'className': 'c-black m-b-18'
        }),
        _.map(this.props.exerciseFilters, repeatCategory3.bind(this)),
        React.createElement('div', { 'className': 'select-chips m-b-20' }, React.createElement(Field, {
            'name': 'cues',
            'component': Select,
            'label': 'Cues',
            'options': this.props.supportingData.cues,
            'valueKey': 'id',
            'labelKey': 'name',
            'multi': true
        })),
        React.createElement('div', { 'className': 'select-chips' }, React.createElement(Field, {
            'name': 'equipments',
            'component': Select,
            'label': 'Equipment',
            'options': this.props.supportingData.equipments,
            'valueKey': 'id',
            'labelKey': 'name',
            'multi': true
        }))
    ]), React.createElement(Card, { 'className': 'metrics p-t-24 p-b-36 p-l-24 p-r-24 m-t-10 box-shadow border-r-10' }, React.createElement(CardTitle, {
        'title': 'Metrics',
        'className': 'c-black m-b-18'
    }), React.createElement('div', { 'className': 'd-flex flex-sb' }, React.createElement('div', { 'className': 'w-48' }, React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'label': 'Sets',
        'name': 'sets',
        'type': 'number',
        'onClick': this.inputClick,
        'component': TextInput
    }), React.createElement('div', {}, React.createElement(Field, {
        'name': 'body_part',
        'component': Select,
        'label': 'Body Part',
        'options': this.props.supportingData.body_part,
        'valueKey': 'id',
        'labelKey': 'name'
    })), React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'label': 'Hold Position',
        'name': 'hold',
        'type': 'number',
        'onClick': this.inputClick,
        'component': TextInput
    }), React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'label': 'Rest Between Sets',
        'name': 'pause',
        'type': 'number',
        'onClick': this.inputClick,
        'component': TextInput
    }), React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'label': 'Weight',
        'name': 'weight',
        'type': 'number',
        'onClick': this.inputClick,
        'component': TextInput
    }), React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'label': 'Target Heart Rate',
        'name': 'heart_rate',
        'type': 'number',
        'onClick': this.inputClick,
        'component': TextInput
    })), React.createElement('div', { 'className': 'w-48' }, React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'label': 'Reps',
        'name': 'reps',
        'type': 'number',
        'onClick': this.inputClick,
        'component': TextInput
    }), React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'label': 'Distance',
        'name': 'distance',
        'type': 'number',
        'component': TextInput
    }), React.createElement(Field, {
        'style': { marginTop: '2px' },
        'name': 'distance_unit',
        'component': Select,
        'label': 'Distance Unit',
        'options': this.props.supportingData.distance_unit
    }), React.createElement(Field, {
        'className': 'w-100 m-b-20',
        'label': 'Duration',
        'name': 'duration',
        'type': 'number',
        'component': TextInput
    }), this.props.metric && (this.props.metric === 3 || this.props.metric.value === 3) ? React.createElement('div', {
        'className': 'col-xs-12 ' + (this.props.pristine ? 'border-around m-b-14 form-field--is-filled' : 'border-around m-b-14 p-relative'),
        'key': '13105'
    }, React.createElement(Field, {
        'className': 'w-100',
        'style': { marginTop: '2px' },
        'name': 'duration_unit',
        'component': Select,
        'label': 'Duration Unit',
        'options': this.props.supportingData.duration_unit
    }), React.createElement(Field, {
        'className': 'w-100 p-t-0 m-b-20',
        'name': 'difficulty',
        'component': Select,
        'label': 'Difficulty Level',
        'options': this.props.supportingData.difficulty,
        '<Field': true
    }, React.createElement(Field, {
        'name': 'pause_unit',
        'component': Select,
        'label': 'Rest between Sets Unit',
        'options': this.props.supportingData.duration_unit + '/'
    }, React.createElement(Field, {
        'name': 'weight_unit',
        'component': Select,
        'label': 'Weight Unit',
        'options': this.props.supportingData.weight_unit + '/'
    })))) : null))), React.createElement(Card, { 'className': 'equipment p-t-24 p-b-36 p-l-24 p-r-24 m-t-10 box-shadow border-r-10' }, React.createElement(CardTitle, {
        'title': 'Equipment',
        'className': 'c-black m-b-18'
    }), React.createElement('div', {}, React.createElement(FieldArray, {
        'name': 'shopify_equipments',
        'component': this.renderEquipments
    })))), React.createElement('div', { 'className': 'publish-info' }, React.createElement(Card, { 'className': 'bg-white box-shadow p-t-24 p-b-24 p-l-24 p-r-24' }, React.createElement('div', { 'className': 'row submit-buttons end-xs m-t-30 m-r-10 middle-xs' }, React.createElement(Button, {
        'label': 'Save as Draft',
        'disabled': this.props.submitting,
        'className': 'bg-success-border m-b-12 m-l-10 w-100',
        'raised': true,
        'onClick': this.saveAsDraft
    }), React.createElement(Button, {
        'label': 'Publish',
        'disabled': this.props.submitting,
        'className': 'm-l-10 bg-success w-100',
        'raised': true,
        'onClick': this.saveAndPublish
    }))))))));
}